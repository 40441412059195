import React from 'react'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSquareTwitter } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';


import '../styles/Footer.css';

export default function Footer() {
  return (
    <div>
      
      <footer>
        <div className='footer-box'>

          <div className="footer-item">
            <h3>About Us</h3>
              <p>We are your Trusted Partner for Building Estimation and Modelling Services.
              At Surya Group, we specialize in providing comprehensive estimation and modelling services tailored to meet the needs of contractors, architects, engineers, property owners, builders, and service providers across the United States & other North American States
              </p>
              <p>This site was developed and maintaine by Mr. Suraj singh
              click <a className='rmv-decoration' target='_blank' href="https://surajmehra.netlify.app/">Here</a>  for more detail about developer and contact related queries</p>
              <div className='dark-icon'>
                <a href="https://twiter.com"><FontAwesomeIcon icon={faSquareTwitter} beatFade size='2x'/></a>
                <a href="https://www.instagram.com/suryagroup.tech/"><FontAwesomeIcon icon={faLinkedin} beatFade size='2x'/></a>
                <a href="https://youtube.com"><FontAwesomeIcon icon={faYoutube} beatFade size='2x'/></a>
                <a href="https://facebook.com"><FontAwesomeIcon icon={faFacebook} beatFade size='2x'/></a>
                
                
                
              </div>
          </div>

          <div className="footer-item">
            <h3>Support</h3>
            <div className='footer-inner-item'>
              <Link className='link-btn' to="/FAQ">FAQ</Link>
              <Link className='link-btn' to="#!">Help</Link>
              <Link className='link-btn' to="/policy/#privacy_policy">Privacy Policy</Link>
              <Link className='link-btn' to="/policy/#terms_condition">Terms and Conditions</Link>
              <Link className='link-btn' to="/policy/#delivery_policy">Shipping and Delivery Policy</Link>
              <Link className='link-btn' to="/policy/#refund_policy">Cancellation and Refund Policy</Link>
              <Link className='link-btn' to="/contact-us">Contact</Link>
            </div>
          </div>

          <div className="footer-item">
          <h3>Quick Link</h3>
            <div className='footer-inner-item'>
              <Link className='link-btn' to="/about">About Us</Link>
              <Link className='link-btn' to="#!">Few Examples</Link>
              <Link className='link-btn' to="#!">How it work</Link>
            </div>
          </div>

          <div className="footer-item">
            <h3>Contact Us</h3>
            <div className='footer-inner-item'>
              <a className='link-btn' href='mailto:email@example.com'>Info@suryagroup.us</a>
              <a className='link-btn' href='https://wa.me/9634377090'>+91 9634377090</a>
            </div>
          </div>
        </div>
        <div id='copyright'>
          <p>© 2024 Surya Group. All rights reserved.</p>
        </div>

      </footer>

    </div>
  )
}
