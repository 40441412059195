import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

import '../../../styles/services/global_service.css'

export default function Electrical() {
  return (
    <section>
        <div className='main-width'>

        <div className='heading-sr'>
            <div>
              <h2>Lumber Estimation</h2>
              <div><p>Home / Services / Lumber Estimation</p></div>
            </div>
            <div>
              <a className='animated-btn-meeting' target='__blank' href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ3qbLnMjn4KgcVjoZHNhlHnYs2QqLJ5GH6Vg35YbPfpiS3aV1tt_C58uqHZMNmjxF7LKzSuq1pG?gv=true">Schedule a Meeting</a>
              <a className='animated-btn-meeting' target='__blank' href="/login">Register portal</a>
            </div>
          </div>

          <div className='service-container' id = 'background-elect' >
            <div className='ser-flex'>
              <div>
                <h4>Illuminating the Path to Successful Electrical Projects</h4>
                <p>Welcome to Surya Group's Electrical Estimation Services. We're here to ensure your electrical projects are powered up with accuracy, efficiency, and cost-effectiveness. Whether you're working on a cozy home renovation or a sprawling commercial complex, we've got the expertise to keep your electrical estimates on point.</p>
              </div>
            </div>


            <div>
              <div className='offer-ser'>
                <h4>Our Electrifying Services</h4>
                <FontAwesomeIcon icon={faArrowDown} bounce />
                <FontAwesomeIcon icon={faArrowDown} bounce />
                <FontAwesomeIcon icon={faArrowDown} bounce />
              </div>
              <h6>1. Comprehensive Material Takeoff</h6>
              <p> We leave no wire uncounted:
                <ol type='a'>
                  <li>Wiring and cabling for all your power needs.</li>
                  <li>Electrical panels, breakers, and switches.</li>
                  <li>Light fixtures that brighten every space.</li>
                  <li>Outlets, boxes, and connectors galore.</li>
                  <li>Safety systems to keep everything secure.</li>
                </ol>
              </p>
            </div>

            <div>
              <h6>2. Precise Circuit and Load Calculations</h6>
              <p> We crunch the numbers, so you don't have to:
                <ol type='a'>
                  <li> Power load calculations for any application.</li>
                  <li> Smart circuit distribution to prevent overloads.</li>
                  <li> Detailed breakdowns for lighting, HVAC, and data systems.</li>
                </ol>
              </p>
            </div>

            <div>
              <h6>3. Labor & Installation Cost Estimation </h6>
              <p>From plugs to panels, we've got it covered:
                <ol type='a'>
                  <li> Installation estimates for new builds and renovations.</li>
                  <li> Specialized labor for high-voltage or complex systems.</li>
                  <li> Safety system installation costs.</li>
                </ol>
              </p>
            </div>

            <div>
              <h6>4. Budgeting & Quotation Support</h6>
              <p> Keeping your finances as balanced as your circuits:
                <ol type='a'>
                  <li> Itemized cost estimates for materials, labor, and equipment.</li>
                  <li> Value engineering options to optimize your design.</li>
                  <li> Transparent, customizable client quotations.</li>
                </ol>
              </p>
            </div>

            <div>
              <h6>5. Invoicing & Material Cost Tracking</h6>
              <p> We keep tabs on every penny:
                <ol type='a'>
                  <li>Detailed lists of materials and costs.</li>
                  <li>Ongoing cost tracking to prevent budget shocks.</li>
                  <li> Professional invoicing for smooth fiscal management.</li>
                </ol>
              </p>
            </div>

           

            <div>
              <h5>Why Surya Group Stands Out from the Crowd</h5>
              <p>
                <ul>
                  <li><strong>Accuracy That Sparks Joy:  </strong> Our estimates are so precise; they could light up a city.</li>
                  <li><strong>Time-Saving Wizardry:</strong>  We handle the number-crunching, you focus on bringing the project to life.</li>
                  <li><strong> Budget-Friendly Brilliance: </strong> We find smart ways to save without dimming your project's shine.</li>
                  <li><strong> Tailored Solutions:  </strong> From simple switches to complex industrial systems, we've got you covered.</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>Who Benefits from Our Electric Expertise?</h5>
              <p>
                <ul>

                  <li><strong>General Contractors: </strong>Keep your projects humming along smoothly.</li>
                  <li><strong>Subcontractors: </strong>  Focus on installation while we handle the calculations.</li>
                  <li><strong>Project Managers: </strong>  Stay in control with crystal-clear cost projections.</li>
                  <li><strong>Developers: </strong> Keep your projects (and profits) right on track.</li>
                  <li><strong>Architects:  </strong> Design with confidence, knowing the numbers add up.</li>
                  <li><strong>Engineers:  </strong>  Get the precise calculations you need for safe, compliant systems.</li>
                  <li><strong>Quantity Surveyors:</strong> Amp up your cost analysis with our detailed breakdowns.</li>
                </ul>
              </p>
            </div>

            <div>
              <h5 className='offer-ser'>Why Choose Surya Group for Your Electrical Estimation Needs?</h5>
              <p>Let's face it - electrical projects can be shockingly complex. But with Surya Group by your side, you'll never feel left in the dark. We're not just number crunchers; we're your partners in powering up success.
                Our team has seen it all - from simple residential rewiring to electrical systems that could power a small city. And you know what? We love every volt of it. Why? Because we're passionate about helping your projects shine bright.
                Think of us as your project's personal electricians, but for numbers. We're here to ensure you have exactly what you need, precisely when you need it. No more budget blowouts, no more mid-project panic orders. Just smooth sailing from blueprint to final inspection.</p>
            </div>

            <div>
              <h5 className='offer-ser'>Ready to Energize Your Next Project?</h5>
              <p>Don't let electrical estimation uncertainties keep you up at night. Reach out to Surya Group today, and let's discuss how we can illuminate the path to your project's success.

Remember, a great electrical system starts with a brilliant estimate. Let's power up something amazing together!

Contact us now for a free consultation. It's time to turn your electrical estimation challenges into opportunities that truly spark joy!</p>
            </div>
           


          </div> 
        </div>
    </section>  
  )
}
