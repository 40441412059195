import React, { useEffect } from 'react';

import '../styles/Navbar.css';
import '../styles/Mediaquerry.css';


import logo from "../assets/images/logo.png"
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';

const Navbar = () => {
    useEffect(() => {
      const navLinks = document.querySelectorAll('.nav-link');
  
      // Add click event to each nav-link
      navLinks.forEach(link => {
        link.addEventListener('click', function () {
          // Remove active class from all links
          navLinks.forEach(link => link.classList.remove('active'));
          // Add active class to the clicked link
          this.classList.add('active');
        });
      });
  
      // Cleanup
      return () => {
        navLinks.forEach(link => link.removeEventListener('click', this));
      };
    }, []);

  return (
            <>
            <navbar>
                <div id='top-bar'>
                    <div> <a className='link-btn' href='mailto:email@example.com'>Info@suryagroup.us</a></div>
                    <div><a  className='link-btn' href='https://wa.me/9634377090'>+91 9634377090</a></div>
                    <div className='nav-icon'>
                        <a href='https://www.instagram.com/suryagroup.tech/' target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>
                        
                    </div>
                    
                </div>
                

                <header>
                    <ul className='nav-ele'>
                        <li><Link className=' nav-link link-btn active' to="/">Home </Link></li>
                        <li><Link className=' nav-link link-btn ' to="/about">About</Link></li>
                        <li  id='drop-access'><div className='link-btn' style={{lineHeight:"100px"}}>Services </div>
                            <div id='visiblity' className='dropdown-bar nav-link'><ul>
                                <li className='dropdown-btn'><Link className='link-btn ' to="/general-contractor">General Contractor</Link></li>
                                <li id='drop-access-inner' className='dropdown-btn'><Link className='link-btn ' to="/">Sub-Contractor</Link>
                                    <div className='dropdown-inner' id='dropdown-inner-item' >
                                        <div >
                                        <div className='left-drop'>
                                                <div>
                                                    <h6>Finishes</h6>  
                                                    <div>
                                                        <Link className="link-btn " to = "/services/Flooring Estimation">Flooring Estimation</Link>
                                                        <Link className="link-btn " to = "/services/Drywall Estimation">Drywall Estimation</Link>
                                                        <Link className="link-btn " to = "/services/Painting Estimation">Painting Estimation</Link>
                                                        <Link className="link-btn " to = "/services/Siding Estimation">Siding Estimation</Link>
                                                    </div>                                      
                                                </div>
                                            </div>

                                            <div>
                                                <div className='middle-drop'>
                                                    <h6>Structural & Envelope</h6>                                        
                                                    <div>
                                                            <Link className="link-btn" to = "/services/Lumber Estimation">Lumber Estimation</Link>
                                                            <Link className="link-btn" to = "/services/Concrete Estimation">Concrete Estimation</Link>
                                                            <Link className="link-btn" to = "/services/Masonry Estimation">Masonry Estimation</Link>
                                                            <Link className="link-btn" to = "/services/Roofing Estimation">Roofing Estimation</Link>
                                                            <Link className="link-btn" to = "/services/Insulation & Moisture Protection">Insulation & Moisture Protection</Link>
                                                            <Link className="link-btn" to = "/services/Demolition Estimation">Demolition Estimation</Link>
                                                        </div>
                                                </div>
                                            </div>
                                            
                                            <div className='right-drop'>
                                                <div>
                                                    <h6>Mechanical, Electrical & Specialty</h6> 
                                                    <div>
                                                        <Link className="link-btn" to = "/services/Electrical Estimation">Electrical Estimation</Link>
                                                        <Link className="link-btn" to = "/services/MEP Estimation">MEP Estimation</Link>
                                                        <Link className="link-btn" to = "/services/Openings & Hardware">Openings & Hardware</Link>
                                                    </div>                                       
                                                </div>
                                            </div>
                                        </div>
                                        {/* <li className='dropdown-btn' ><Link className=' nav-link link-btn ' to="#!">Lumber Takeoff</Link></li> */}
                                    </div>
                                </li>
                                </ul></div>
                        </li>
                        <div id='logo'><Link to="/" ><img src={logo}  alt='suryagroup.us'/></Link></div>
                        <li><Link className=' nav-link link-btn ' to="/blog">Blog</Link></li>
                        <li><Link className=' nav-link link-btn ' to="/faq">FAQs</Link></li>                
                        <li><Link className=' nav-link link-btn ' to="/contact-us">Contact Us</Link></li>


                        
                        <li  id='drop-access'><div className='link-btn portal' ><div className='animated-button' id='our-portal'>Our Plateform</div> </div>
                            <div id='visiblity' className='dropdown-bar'>
                                <ul>
                                    <li className='dropdown-btn'><Link className='link-btn ' to="/login">Client User</Link></li>
                                    <li className='dropdown-btn'><a className='link-btn ' target='__blank' href="https://admin.suryagroup.us/">Admin Portal</a></li>
                                </ul>
                            </div>
                        </li>    


                    </ul>
                    
                </header>
            </navbar>
        </>
    )
}


export default Navbar;




