import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../styles/Faqs.css";

export default function Faq() {
  const [expandedItems, setExpandedItems] = useState({});

  // Toggle function for a specific FAQ item
  const toggleFAQ = (index) => {
    setExpandedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  // FAQ Data
  const faqData = [
    {
      category: "Supports FAQs",
      items: [
        {
          question: "1. How can I contact customer support?",
          answer:
            'You can reach us via email at <a href="mailto:info@suryagroup.us">info@suryagroup.us</a> or <a href="https://calendar.google.com/calendar/appointments/schedules/AcZssZ3qbLnMjn4KgcVjoZHNhlHnYs2QqLJ5GH6Vg35YbPfpiS3aV1tt_C58uqHZMNmjxF7LKzSuq1pG?gv=true" target="_blank">schedule a meeting</a> during business hours.',
        },
        {
          question: "2. What is the typical response time for support inquiries?",
          answer: "Our team strives to respond within 24 hours to all inquiries.",
        },
        {
          question: "3. Do you offer live chat support?",
          answer:
            "Yes, live chat support is available during working hours through our website.",
        },
        {
          question: "4. What kind of technical support is provided?",
          answer:
            "We help with account setup, platform navigation, and resolving technical issues.",
        },
        {
          question: "5. Is multilingual support available?",
          answer: "Yes, we provide support in English to cater to a diverse clientele.",
        },
      ],
    },
    {
      category: "Future FAQs",
      items: [
        {
          question: "1. What are the upcoming features of Surya Group's platform?",
          answer:
            "We continuously work on improving our platform. Stay tuned for updates and new features tailored for construction professionals.",
        },
        {
          question: "2. Will Surya Group expand its services beyond estimation?",
          answer:
            "Yes, we aim to broaden our service offerings to include more project management solutions.",
        },
        {
          question: "3. How can I suggest new features or improvements?",
          answer:
            "We value your feedback! Please get in touch with us through our feedback form or email to share your suggestions.",
        },
        {
          question: "4. Can I access training to use the new features?",
          answer:
            "Absolutely! We'll provide training materials and dedicated support for all updates.",
        },
      ],
    },
    {
      category: "Payment FAQs",
      items: [
        {
          question: "1. What payment methods do you accept?",
          answer:
            "We accept credit/debit cards, and digital payments through popular platforms.",
        },
        {
          question: "2. Are there any discounts for long-term contracts?",
          answer:
            "Yes, we offer competitive discounts for long-term partnerships. Please contact us for details.",
        },
        {
          question: "3. Is there a refund policy?",
          answer:
            'Yes, refunds are processed as per our cancellation and refund policy. For more, <a href="/policy/#refund_policy">click here</a>.',
        },
        {
          question: "4. Do you offer payment plans?",
          answer:
            "Yes, flexible payment options are available for larger projects.",
        },
        {
          question: "5. How do I receive invoices for payments?",
          answer: "Invoices will be sent to your registered email.",
        },
      ],
    },
    {
      category: "User FAQs",
      items: [
        {
          question: "1. Do I need to register to use the Surya Group platform?",
          answer:
            "Yes, registration is mandatory to access the platform. Separate accounts are available for client users (Subcontractors, General Contractors, Builders, Project Managers) and admin users.",
        },
        {
          question:
            "2. Can I add other team members from my company to the platform?",
          answer:
            "Yes, registered clients can invite and add other users from their company to collaborate on projects.",
        },
        {
          question: "3. What are the differences between client and admin user roles?",
          answer:
            "Client users can view and manage their projects, send bids, and track progress, while admin users oversee platform management and provide support.",
        },
        {
          question: "4. How do I recover my account if I forget my login credentials?",
          answer:
            'You can reset your password using the "Forgot Password" option on the login page or contact support for help.',
        },
        {
          question:
            "5. Can multiple users from the same company work on the platform simultaneously?",
          answer:
            "Yes, our platform supports multiple users from the same company working collaboratively in real time.",
        },
        {
          question:
            "6. How do I update my account details or permissions for team members?",
          answer:
            "You can manage your account and permissions for added users through your profile settings.",
        },
        {
          question: "7. What should I do if I meet an error while adding a user?",
          answer:
            "Please ensure all required fields are correctly filled. If the issue persists, contact our support team for help.",
        },
      ],
    },
  ];

  return (
    <section>
      <div className="main-width">
        <div>
          <div className="faq-head">
            <h1>Frequently Asked Questions</h1>
            <p>
              Do you have any questions about SuryaGroup and estimation? See the
              list below for our most frequently asked questions. If your
              questions are not listed here, please
              <Link to="/contact-us"> contact us</Link>.
            </p>
          </div>
          <div className="faq">
            {faqData.map((faqCategory, categoryIndex) => (
              <div key={categoryIndex} className="faq-box">
                <div className="faq-sub-box">
                  <h3>{faqCategory.category}</h3>
                  <div className="faq-section">
                    {faqCategory.items.map((item, itemIndex) => {
                      const faqIndex = `${categoryIndex}-${itemIndex}`;
                      const isExpanded = expandedItems[faqIndex];

                      return (
                        <div key={itemIndex} className="faq-item">
                          <div className="faq-question">
                            <span>{item.question}</span>
                            <button
                              className="faq-toggle"
                              onClick={() => toggleFAQ(faqIndex)}
                            >
                              {isExpanded ? "-" : "+"}
                            </button>
                          </div>
                          <div
                            className="faq-answer"
                            style={{
                              maxHeight: isExpanded ? "200px" : "0",
                              overflow: "hidden",
                              transition: "max-height 0.3s ease",
                            }}
                          >
                            <p dangerouslySetInnerHTML={{ __html: item.answer }} />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
