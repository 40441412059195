import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

import '../../../styles/services/global_service.css'

export default function Insulation() {
  return (
    <section>
        <div className='main-width'>

        <div className='heading-sr'>
            <div>
              <h2>Lumber Estimation</h2>
              <div><p>Home / Services / Lumber Estimation</p></div>
            </div>
            <div>
              <a className='animated-btn-meeting' target='__blank' href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ3qbLnMjn4KgcVjoZHNhlHnYs2QqLJ5GH6Vg35YbPfpiS3aV1tt_C58uqHZMNmjxF7LKzSuq1pG?gv=true">Schedule a Meeting</a>
              <a className='animated-btn-meeting' target='__blank' href="/login">Register portal</a>
            </div>
          </div>

          <div className='service-container' id = 'background-insulation' >
            <div className='ser-flex'>
              <div>
                <h4>Surya Group: Insulation & Moisture Protection Estimation</h4>
                <strong>Wrapping Your Projects in Comfort and Security </strong>
                <p>Welcome to Surya Group's Insulation & Moisture Protection Estimation Services. We are here to ensure your buildings stay cozy, dry, and energy efficient. From snug homes to expansive commercial spaces, we have the expertise to keep your projects well-insulated and water-tight, all while keeping your budget intact.</p>
              </div>
            </div>


            <div>
              <div className='offer-ser'>
                <h4>Our Warm and Dry Services: </h4>
                <FontAwesomeIcon icon={faArrowDown} bounce />
                <FontAwesomeIcon icon={faArrowDown} bounce />
                <FontAwesomeIcon icon={faArrowDown} bounce />
              </div>
              <h5> 1. Comprehensive Material Takeoff : </h5>
              <p>We've got you covered, literally:
                <ol type='a'>
                  <li> Insulation materials (fiberglass, spray foam, cellulose, you name it!)</li>
                  <li> Vapor barriers and waterproofing systems</li>
                  <li> All the fasteners, sealants, and tapes you will need</li>
                  <li> Flashing and drainage systems to keep water at bay</li>
                </ol>
              </p>
            </div>

            <div>
              <h5> 2. Precise Area and Volume Measurement</h5>
              <p> We measure every nook and cranny:
                <ol type='a'>
                  <li>Wall, floor, and roof calculations</li>
                  <li>Foundation and below-grade protection</li>
                  <li>Attics and crawl spaces (we do not forget the tight spots!)</li>
                  <li>Moisture-prone areas like windows and doors</li>
                </ol>
              </p>
            </div>

            <div>
              <h5>3. Labor Cost Estimation:</h5>
              <p> From installation to application, we have the numbers:
                <ol type='a'>
                  <li>Labor hours for insulation, even in those hard-to-reach places</li>
                  <li>Specialized crews for moisture protection systems</li>
                  <li>Application of coatings and membranes</li>
                </ol>
              </p>
            </div>

            <div>
              <h5>4. Budgeting & Quotation Support:</h5>
              <p> Keeping your finances as dry as your basements:
                <ol type='a'>
                  <li>Detailed cost breakdowns for materials and labor</li>
                  <li>Smart recommendations for cost-effective, high-performance choices.</li>
                  <li>Crystal-clear client quotations</li>
                </ol>
              </p>
            </div>

            <div>
              <h5>5. Invoicing & Material Cost Tracking:</h5>
              <p> We track every roll and spray:
                <ul>
                  <li>Itemized lists of all materials and components</li>
                  <li>Ongoing cost tracking to keep spending in check</li>
                  <li>Professional invoicing for smooth financial sailing</li>
                </ul>
              </p>
            </div>

           

            <div>
              <h5>Why Surya Group is Your Insulation & Moisture Protection Ally</h5>
              <p>
                <ul>
                  <li><strong>Precision That Doesn't Leak:   </strong>  Our estimates are as tight as the buildings you are protecting.</li>
                  <li><strong>Energy Efficiency Wizards:</strong> We help you save on materials now and energy bills later.</li>
                  <li><strong>Moisture Masters: </strong>  We have the expertise to keep water where it belongs - outside!</li>
                  <li><strong> Budget-Friendly Excellence:   </strong>We help you invest wisely in protection that lasts.</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>Who Benefits from Our Cozy, Dry Expertise?</h5>
              <p>
                <ul>

                  <li><strong>General Contractors: </strong> Keep your projects as snug as a bug in a rug.</li>
                  <li><strong>Subcontractors: </strong> Focus on flawless installation while we crunch the numbers.</li>
                  <li><strong>Project Managers: </strong> Stay in control with estimates as solid as a vapor barrier.</li>
                  <li><strong>Developers: </strong> Build long-lasting, efficient structures that stand the test of time.</li>
                  <li><strong>Architects:  </strong> Design with confidence, knowing every layer is accounted for.</li>
                  <li><strong>Engineers:  </strong> Get the exact specs you need for high-performance building envelopes.</li>
                  <li><strong>Quantity Surveyors:</strong> Beef up your cost analysis with our detailed breakdowns.</li>
                </ul>
              </p>
            </div>

            <div>
              <h5 className='offer-ser'>Why Choose Surya Group for Your Insulation & Moisture Protection Needs?</h5>
              <p>Let us face it - keeping buildings warm, dry, and efficient can be as tricky as navigating a leaky roof. But with Surya Group by your side, you will never feel left out in the cold. We are not just estimators; we are your partners in creating comfortable, durable spaces.
                  Our team has seen it all - from simple attic insulation to complex waterproofing systems that would make a submarine captain jealous. And you know what? We love every Fiber and membrane of it. Why? Because we are passionate about helping your projects stay cozy, dry, and energy-efficient for years to come.
                  Think of us as your project's personal climate control experts. We are here to ensure you have exactly what you need, precisely when you need it. No more sweating over material shortages or getting soaked by unexpected costs. Just smooth sailing from foundation to rooftop.</p>
            </div>
            <div>
              <h5 className='offer-ser'>Why Choose Surya Group for Your Insulation & Moisture Protection Needs?</h5>
              <p>Do not let insulation and moisture protection complexities dampen your spirits. Reach out to Surya Group today, and let us discuss how we can create a snug, dry haven for your next project.
                Remember, a great building starts with a solid (and dry!) foundation. Let us build something cozy and lasting together!
                Contact us now for a free consultation. It is time to turn your insulation and moisture protection challenges into opportunities that truly shine - and stay dry!</p>
            </div>


          </div> 
        </div>
    </section>  
  )
}
