import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';

import '../../../styles/services/global_service.css'

export default function Mep() {
  return (
    <section>
        <div className='main-width'>

        <div className='heading-sr'>
            <div>
              <h2>Lumber Estimation</h2>
              <div><p>Home / Services / Lumber Estimation</p></div>
            </div>
            <div>
              <a className='animated-btn-meeting' target='__blank' href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ3qbLnMjn4KgcVjoZHNhlHnYs2QqLJ5GH6Vg35YbPfpiS3aV1tt_C58uqHZMNmjxF7LKzSuq1pG?gv=true">Schedule a Meeting</a>
              <a className='animated-btn-meeting' target='__blank' href="/login">Register portal</a>
            </div>
          </div>
          <div className='service-container' id = 'background-mep' >
            <div className='ser-flex'>
              <div>
                <h4>MEP Estimation Service: Streamling Mechanical, Electrical, and Plumbing Systems for Efficient Projects</h4>
                <p>You may manage and budget for every component of your plumbing, electrical, and mechanical systems with the help of <strong> Surya Group</strong>'s thorough and detailed MEP estimation services. Our estimates, whether for commercial, residential, or 
                  industrial projects, are made to guarantee accurate material procurement, cost-effective labor management, and effective planning.</p>
              </div>
              <div ></div>
            </div>


            <div>
              <div className='offer-ser'>
                <h4>What We Provide: </h4>
                <FontAwesomeIcon icon={faArrowDown} bounce />
                <FontAwesomeIcon icon={faArrowDown} bounce />
                <FontAwesomeIcon icon={faArrowDown} bounce />
              </div>
              <h5>1. Full Takeoff of MEP Materials:</h5>
              <p>A thorough analysis of all the materials required for the project's mechanical, electrical, and plumbing components is part of our MEP estimation services. We cover:
                <ul>
                  <li><strong> Mechanical: </strong> HVAC systems, ductwork, ventilation, heating and cooling units, boilers, chillers, and insulation</li>
                  <li><strong> Electrical: </strong> Wiring, conduits, light fixtures, outlets, panels, transformers, and safety systems</li>
                  <li><strong> Plumbing: </strong> Piping, fixtures, valves, fittings, water heaters, drainage systems, and gas lines</li>
                </ul>
              </p>
            </div>
            
            <div>
              <h5>2. Accurate System Sizing & Load Calculations:</h5>
              <p>We ensure the correct sizing and load calculations for each system, refining performance and preventing over- or under-specification. Our services include:
                <ul>
                  <li>· HVAC load calculations and duct sizing for best temperature control and energy efficiency</li>
                  <li>· Electrical load calculations to prevent overloads and ensure balanced power distribution.</li>
                  <li>· Plumbing sizing for water supply, waste, and vent systems to ensure proper pressure and flow.</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>3. Labor & Installation Cost Estimation:</h5>
              <p>We provide comprehensive labor estimates for the installation of MEP systems, ensuring an efficient workflow and proper resource allocation. Our estimates include:
                <ul>
                  <li> Installation of HVAC units, ductwork, and associated components</li>
                  <li> Labor for electrical system installations, including wiring, lighting, and safety devices.</li>
                  <li> Plumbing labor for piping installation, fixture setup, and gas line work</li>
                  <li> Specialized labor for complex MEP systems such as central HVAC, industrial plumbing, and high-voltage electrical work </li>
                </ul>
              </p>
            </div>

            <div>
              <h5>4. Budgeting & Quotation Support:</h5>
              <p>Our budgeting services provide a clear and comprehensive breakdown of costs, allowing you to plan effectively. We offer:
                <ul>
                  <li> Detailed cost estimates for mechanical, electrical, and plumbing materials and labor</li>
                  <li> Recommendations for value engineering, helping to perfect system performance while reducing costs.</li>
                  <li> Customizable quotations for clients, providing transparency and confidence in project planning.</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>5. Invoicing & Material Cost Tracking:</h5>
              <p>We offer professional invoicing and ongoing cost tracking to ensure your MEP project stays within budget. Our services include:
                <ul>
                  <li> Itemized material lists with associated costs for mechanical, electrical, and plumbing components</li>
                  <li> Ongoing tracking of material and labor costs to prevent overspending</li>
                  <li> Clear and professional invoicing, making it easy to manage the financial aspects of the project.</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>Why Choose Our MEP Estimation Services?</h5>
              <p>
                <ul>
                  <li><strong> Comprehensive & Integrated Solutions:  </strong> We provide a complete MEP estimation package, ensuring that all mechanical, electrical, and plumbing systems are seamlessly coordinated for efficient project delivery.</li>
                  <li><strong> Accurate & Detailed Estimates: </strong>  Our precise material takeoffs and labor cost estimates help you avoid costly errors and stay within budget.</li>
                  <li><strong> Time & Cost Efficiency: </strong> We focus on delivering estimates that perfect material usage, reduce labor inefficiencies, and prevent delays.</li>
                  <li><strong> Tailored to Your Needs:  </strong> Whether it is a large commercial build or a residential project, our estimates are tailored to meet the specific needs of your construction.</li>
                </ul>
              </p>
            </div>

            <div>
              <h5>Who Can Benefit from Our MEP Estimation Services?</h5>
              <p>
                <ul>

                  <li><strong>General Contractors: </strong>  Simplify project management with correct MEP estimates that ensure every system is carefully reviewed and budgeted.</li>
                  <li><strong> Subcontractors: </strong> Streamline your workflow with detailed takeoffs for HVAC, electrical, and plumbing systems, allowing you to focus on installation.</li>
                  <li><strong>Project Managers: </strong> Gain complete control over MEP material usage and labor costs with clear, reliable estimates that guide project execution.</li>
                  <li><strong>Developers:   </strong> Keep your projects on track with detailed cost estimates for all MEP systems, ensuring prompt and cost-effective project delivery.</li>
                  <li><strong>Architects:  </strong>  Ensure your designs are fully supported by precise estimates for MEP systems, helping you meet performance and safety goals.</li>
                  <li><strong> Engineers:</strong>  Receive exact calculations for HVAC loads, electrical circuits, and plumbing sizing, ensuring compliance with building codes and safety standards.</li>
                  <li><strong> Quantity Surveyors: </strong>  Enhance your cost analysis with detailed breakdowns of mechanical, electrical, and plumbing materials, labor, and equipment, ensuring effective project planning and economic management.</li>
                </ul>
              </p>
            </div>

            <div>
              <h5 className='offer-ser'>Get Started Today!</h5>
              <p>Let us take care of your MEP estimation needs, so you can focus on delivering projects with superior mechanical, electrical, and plumbing systems. Contact us today for a <strong> free consultation</strong> and discover how our expert estimation services can streamline your next project.
              </p>
            </div>
          </div>  

        </div>
    </section>
  )
}
