import React, { useState } from 'react';

import '../../styles/contact.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import {faLocationDot} from '@fortawesome/free-solid-svg-icons';

export default function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form submitted:', formData);
        alert('Thank you ' + formData['name'] + '! Form submitted and we will contact you next 24 hours.');

        // Reset form data to clear the inputs
        setFormData({
            name: '',
            email: '',
            phone: '',
            message: '',
        });
    };

  return (
    <section>
        <div className='main-width'>


            <div className='com-background' id='contact-back'>
                <div className='com-container'>
                    <h1>Contact Us</h1>
                    <p>Home / Contact Us</p>

                </div>
            </div>

            
                <div className='contact-menu'>

                    <div className='contact-inner'>
                        <div className = 'com-logo'><FontAwesomeIcon icon={faEnvelope} /></div>  
                        <h4>Email (Landline) </h4>    
                        <a class=" nav-link link-btn " href="mailto:Info@Estimation.us">Info@suryagroup.us</a>
                
                    </div>
                    
                    <div className='contact-inner'>
                        <div className='com-logo'><FontAwesomeIcon icon={faPhone} /></div>
                        <h4>Call (Landline) </h4>
                        <a class=" nav-link link-btn " href="tel:+919634377090">+91 9634377090</a>
                        <a class=" nav-link link-btn " href="tel:+918077414663">+91 8077414663</a>

                    </div>

                    <div className='contact-inner'>
                        <div className='com-logo'><FontAwesomeIcon icon={faLocationDot} /></div>
                        <h4>Our Office Location</h4>
                        <address>
                            012 Main Street, <br/>Bageshwar,<br/> Uttarakhand,<br/> 263642,<br/> India
                        </address>

                    </div>
                </div>

                <div>
                    <div className='contact-box'>
                        <div className='contact-left'>
                            <div>
                                <div>
                                    <img 
                                        src='https://familykarateclubs.co.uk/sitepad-data/uploads/2021/06/Contact_Us_Logo.jpg'
                                        alt='Loading....'
                                        width='300'
                                    />
                                </div>
                                <div className='contact-para'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse cum cupiditate, accusamus molestias harum iusto earum unde tenetur consectetur, provident non? Animi perferendis recusandae ad, maxime expedita eos. Quo velit eaque animi, eius sequi sunt eos error tempore quisquam atque.</div>
                            </div>
                        </div>
                        <div className='contact-right'>
                            <div><h1>Contact Us</h1></div>
                            <div className='contact-form'>
                                <form onSubmit={handleSubmit}>
                                    <div className='input-cvr'>
                                        <input  className='form-input'
                                        type="text"
                                        id='name'
                                        name='name'
                                        value={formData.name}
                                        onChange={handleChange}
                                        placeholder='Enter your name'
                                        required
                                        />
                                    </div>
                                    <div className='input-cvr'>
                                        <input  className='form-input'
                                        type="email"
                                        id='email'
                                        name='email'
                                        onChange={handleChange}
                                        value={formData.email}
                                        placeholder='Enter your Email'
                                        required
                                        />
                                    </div>
                                    <div className='input-cvr'>
                                        <textarea 
                                        rows='5'
                                        cols='10'
                                        name='message'
                                        placeholder='Enter message' 
                                        value={formData.message}
                                        onChange={handleChange}
                                        className='form-input'
                                        >
                                        </textarea>
                                    </div>
                                    <button type='submit'>Submit</button>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>

            

        </div>
    </section>
  )
}
