import React from 'react'
import logo from "../../assets/images/logo.png"
// import { Link } from 'react-router-dom'
import '../../styles/blog.css'

export default function Blog(){
   
    return(
        <section>
            <div className="main-width">
                <div>
                    <div className="blog-main">
                        <h1>Estimating Blogs</h1>
                        <div className="blog-section">

                            <div className="blog-item">
                                <div className="blog">
                                    <h5>Top 10 Contractor Strategies for Success in 2025</h5>
                                    <div className="items-center">
                                        <div><img src={logo} alt="SuryaGroup logo"/></div>
                                        <a href="https://suryagoup.us/">Surya Group</a>
                                        <span>.</span>
                                        <span>Oct 11, 2024</span>
                                    </div>
                                    <p>As we approach 2025, the construction industry continues to evolve, presenting both challenges and opportunities for contractors. Whether your focus is residential, multifamily, or commercial projects, implementing these top 10 strategies will position you for success in the coming years....</p>
                                </div>
                                <div><a className='blog-btn' target='__blank' href="https://www.linkedin.com/pulse/top-10-contractor-strategies-success-2025-suryagorup-steac/?trackingId=UPeO3nCmaZ4QsxlcBiQz8A%3D%3D">Read more</a></div>
                            </div>
                            <div className="blog-item">
                                <div className="blog">
                                    <h5>The Secrets to Winning Construction Bids</h5>
                                    <div className="items-center">
                                        <div><img src={logo} alt="SuryaGroup logo"/></div>
                                        <a href="https://suryagoup.us/">Surya Group</a>
                                        <span>.</span>
                                        <span>May 20, 2024</span>
                                    </div>
                                    <p>Have you been struggling to secure construction bids? The key might be in shifting your perspective to align with the priorities of the project owners or general contractors. In this blog post, we'll delve into essential tips and strategies to help you win more construction bids by understanding what truly matters to the decision-makers in the industry......</p>
                                </div>
                                <div><a className='blog-btn' target='__blank' href="https://www.linkedin.com/pulse/elevate-your-space-magic-color-cool-vs-warm-hues-unveiled-m0psc/">Read more</a></div>
                                
                            </div>
                            <div className="blog-item">
                                <div className="blog">
                                    <h5>Elevate Your Space with the Magic of Color: Cool vs. Warm Hues Unveiled🎨</h5>
                                    <div className="items-center">
                                        <div><img src={logo} alt="SuryaGroup logo"/></div>
                                        <a href="https://suryagoup.us/">Surya Group</a>
                                        <span>.</span>
                                        <span>Nov 12, 2024</span>
                                    </div>
                                    <p>Unlock the true potential of your interior spaces by harnessing the captivating power of color. As painting experts, we know that the temperature of your color palette - whether cool or warm - can dramatically reshape the ambiance, mood, and overall aesthetic of a room. In this enlightening exploration, we'll guide you through the unique qualities of cool and warm tones, empowering you to curate color palettes that elevate your living experience....</p>
                                </div>
                                <div><a className='blog-btn' target='__blank' href="https://www.linkedin.com/pulse/elevate-your-space-magic-color-cool-vs-warm-hues-unveiled-m0psc/">Read more</a></div>
                            </div>
                            {/* <div className="blog-item">
                                <div className="blog">
                                    <h5>How to Open the Terminal in Visual Studio Code?</h5>
                                    <div className="items-center">
                                        <div><img src={logo} alt="SuryaGroup logo"/></div>
                                        <a href="https://suryagoup.us/">Surya Group</a>
                                        <span>.</span>
                                        <span>Dec 8, 2024</span>
                                    </div>
                                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Delectus commodi alias aliquam corrupti magnam enim, libero ab! Distinctio illo mollitia ullam repudiandae molestiae magni ipsum, sequi asperiores eaque pariatur praesentium ab optio rerum nemo reprehenderit. Dolor reiciendis eum voluptates odio eos optio, sed temporibus aliquam quo delectus laboriosam molestias suscipit!</p>
                                </div>
                                <div><a className='blog-btn' href="#!">Read more</a></div>
                            </div> */}


                        </div>
                    </div>
                </div>
                

            </div>
        </section>
    )
}