import React, {useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import "../../styles/policies.css"


export default function Policies() {
    const location = useLocation();

    useEffect(() => {
        const handleHashScroll = () => {
          if (location.hash) {
            const element = document.getElementById(location.hash.slice(1));
            if (element) {
              const navbarHeight = document.querySelector(".navbar")?.offsetHeight || 0;
              const elementPosition = element.offsetTop - navbarHeight - 200; // 10px for extra padding
              window.scrollTo({ top: elementPosition, behavior: "smooth" });
            }
          }
        };
    
        handleHashScroll();
      }, [location]);


  return (
    <section>
    <div className='main-width policy'>
        <div id='privacy_policy'>
        <h2>Privacy Policy</h2>
        <p>Welcome to Suryagroup. Your privacy is important to us, and we are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you use our services.</p>

        <h4>Information We Collect</h4>
        <p>We may collect personal and business details such as your name, email address, phone number, and payment information to provide our services.</p>

        <h4>How We Use Your Information</h4>
        <ul>
            <li>To process transactions securely</li>
            <li>To improve our services</li>
            <li>To communicate updates and offers</li>
        </ul>

        <h4>Data Security</h4>
        <p>We use industry-standard security measures to protect your information.</p>

        <h4>Contact Us</h4>
        <p>If you have any questions about our privacy practices, please email us at <a href="mailto:Info@suryagroup.us">Info@suryagroup.us</a>.</p>
        </div>

        <div id='terms_condition'>
                <h2>Terms and Conditions</h2>
                <p>Welcome to Suryagroup. We appreciate the trust you place in us, and we are committed to delivering reliable, accurate, and professional services. These Terms and Conditions outline the rules and guidelines for using our website and services. By accessing or using our services, you agree to these terms in their entirety.</p>

                <h4>1. Scope of Services</h4>
                <p>We specialize in providing accurate construction estimating, takeoff services, and preconstruction consultancy. Our services are tailored to meet the needs of general contractors, subcontractors, and project managers. We promise to deliver our services with professionalism, honesty, and dedication to your project’s success.</p>

                <h4>2. User Responsibilities</h4>
                <p>When using our services, you agree to:</p>
                <ul>
                    <li>Provide accurate and complete project details, including drawings, specifications, and any other relevant information.</li>
                    <li>Respond to our queries in a timely manner to ensure smooth project progression.</li>
                    <li>Use our deliverables for their intended purposes and avoid redistribution or unauthorized use.</li>
                </ul>

                <h4>3. Payments and Invoicing</h4>
                <p>We aim to keep our payment terms simple and fair:</p>
                <ul>
                    <li>All payments must be made through secure channels, including Razorpay or direct bank transfers.</li>
                    <li>Our invoices are due within the timeline specified in the contract or service agreement.</li>
                    <li>For custom services, a deposit may be required before the project begins.</li>
                </ul>
                <p>If you encounter issues with payments or require clarification, please email us at <a href="mailto:Info@suryagroup.us">Info@suryagroup.us</a>.</p>

                <h4>4. Project Timelines</h4>
                <p>We are committed to delivering on time. Project timelines will be clearly outlined in the service agreement. If unforeseen circumstances arise, we will communicate with you immediately and work to resolve any delays.</p>

                <h4>5. Cancellation Policy</h4>
                <p>We understand that project needs can change. Cancellations must be requested within 24 hours of placing an order. Please email us at <a href="mailto:Info@suryagroup.us">Info@suryagroup.us</a> to process your request.</p>

                <h4>6. Refund Policy</h4>
                <p>Your satisfaction is our priority. Refunds will be issued in the following cases:</p>
                <ul>
                    <li>If services have not yet been initiated.</li>
                    <li>If there was a clear mistake on our end in delivering the agreed-upon services.</li>
                </ul>
                <p>Refund requests are reviewed on a case-by-case basis and processed within 7 business days upon approval.</p>

                <h4>7. Confidentiality</h4>
                <p>We take confidentiality seriously. All documents, plans, and information shared with us will be handled with strict confidentiality. We will never disclose your data to third parties without your consent.</p>

                <h4>8. Limitation of Liability</h4>
                <p>While we strive for excellence, we cannot be held liable for unforeseen issues such as inaccuracies in project details provided by you, third-party delays, or external factors beyond our control. However, we will work closely with you to find solutions should any issues arise.</p>

                <h4>9. Customer Support</h4>
                <p>We are here to help. If you have questions, concerns, or need support, feel free to reach out to us at <a href="mailto:Info@suryagroup.us">Info@suryagroup.us</a>. Our team is committed to providing prompt and professional assistance.</p>

                <h4>10. Modifications to Terms</h4>
                <p>We reserve the right to update or modify these Terms and Conditions at any time. Any changes will be communicated on our website. We encourage you to review these terms periodically to stay informed.</p>

                <h4>11. Governing Law</h4>
                <p>These Terms and Conditions are governed by the laws of India. Any disputes will be resolved amicably or through appropriate legal channels within Delhi's jurisdiction.</p>

                <h4>12. Final Note</h4>
                <p>Thank you for choosing Suryagroup. We value your trust and are dedicated to exceeding your expectations. Your success is our success!</p>

                <p>For any further queries, please contact us at <a href="mailto:Info@suryagroup.us">Info@suryagroup.us</a>.</p>
           

        </div>

        <div id='delivery_policy'>
            <h2>Shipping and Delivery Policy</h2>
            <p>This policy applies to any deliverables as part of our service agreement.</p>

            <h4>Delivery Timeline</h4>
            <p>Deliverables will be sent within the agreed project timeline, typically via email or Client user platforms. It will launch soon in our website.</p>

            <h4>Shipping Charges</h4>
            <p>No physical shipping is applicable; hence, no charges apply.</p>

            <h4>Contact Us</h4>
            <p>For delivery concerns, email us at <a href="mailto:Info@suryagroup.us">Info@suryagroup.us</a>.</p>
        </div>

        <div id='refund_policy'>
                <h2>Cancellation and Refund Policy</h2>
                <p>At Suryagroup, we aim to deliver high-quality construction estimating and consulting services. We understand that circumstances may change, and this policy is designed to address your concerns with cancellations and refunds in a fair and transparent manner.</p>

                <h4>1. Cancellation Policy</h4>
                <p>We allow cancellations for services that have not yet been initiated. Please note the following conditions:</p>
                <ul>
                    <li>Cancellations must be requested within <strong>24 hours</strong> of placing an order.</li>
                    <li>If the service has already begun, cancellations will not be accepted.</li>
                    <li>Cancellations should be requested by sending an email to <a href="mailto:Info@suryagroup.us">Info@suryagroup.us</a> with the required details.</li>
                </ul>
                <p>Once we confirm your cancellation request, you will receive an acknowledgment email. If your cancellation is approved, we will inform you about the next steps.</p>

            <h4>2. Refund Policy</h4>
            <p>Your satisfaction is our priority. Refunds will be processed under the following conditions:</p>
            <ul>
                <li><strong>Before Service Initiation:</strong> Full refunds will be provided for cancellations made before any work has started.</li>
                <li><strong>Partial Refunds:</strong> If a service is partially completed, we will evaluate the completed work and issue a refund for the unused portion of the service.</li>
                <li><strong>Non-Refundable Services:</strong> Once the deliverables (e.g., estimates, reports, takeoffs) have been submitted, refunds will not be issued unless otherwise stated below.</li>
                <li><strong>Scope of Work Compliance:</strong> If the job has been submitted and the takeoff does not meet the agreed-upon scope of work, you may contact us to discuss your concerns. Based on our review, we will consider issuing a full or partial refund depending on the quality of the deliverables and your level of satisfaction.</li>
            </ul>
            <p>Refunds will be processed within <strong>7 business days</strong> after the refund is approved. Refunds will be issued through the same payment method used during the purchase.</p>

                <h4>3. Exceptions</h4>
                <p>Refunds will not be provided in the following cases:</p>
                <ul>
                    <li>If the customer fails to provide complete and accurate project information needed to complete the service.</li>
                    <li>If delays or issues arise due to factors outside Suryagroup's control (e.g., incomplete data, third-party delays).</li>
                    <li>For changes in scope or requirements after the project has started.</li>
                </ul>

                <h4>4. How to Request a Cancellation or Refund</h4>
                <p>To initiate a cancellation or refund request, please follow these steps:</p>
                <ol>
                    <li>Send an email to <a href="mailto:Info@suryagroup.us">Info@suryagroup.us</a> with the subject line "Cancellation/Refund Request".</li>
                    <li>Include the following details in your email:</li>
                    <ul>
                        <li>Your Name</li>
                        <li>Business Name</li>
                        <li>Project Details</li>
                        <li>Scope of Work</li>
                        <li>Reason for Your Request (provide detailed information)</li>
                    </ul>
                    <li>Our support team will review your request and respond within 2-3 business days.</li>
                </ol>
                <p>We strive to resolve your concerns quickly and fairly.</p>

                <h4>5. Our Commitment to Customer Satisfaction</h4>
                <p>We are dedicated to providing excellent service and addressing your concerns promptly. If you feel your issue has not been resolved to your satisfaction, please escalate the matter by emailing us at <a href="mailto:Info@suryagroup.us">Info@suryagroup.us</a>.</p>

                <h4>6. Contact Us</h4>
                <p>If you have any questions about this policy or need further assistance, please reach out to us at:</p>
                <p><strong>Email:</strong> <a href="mailto:Info@suryagroup.us">Info@suryagroup.us</a></p>
                <p>We value your trust and strive to ensure a seamless experience with our services.</p>
        </div>


    </div>
    </section>
  )
}
